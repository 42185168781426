import { Button, Modal } from "antd";
import Link from "next/link";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddToSlackButton from "@buddieshr/components/addToSlackButton";
import { VideoCameraOutlined } from "@ant-design/icons";

const WatchADemoButton = ({ app }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal
        title="Demo video - Alfy Matching"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={900}
      >
        <iframe
          width="100%"
          height="420"
          src="https://www.youtube.com/embed/JufnUWYVeL8?si=1oGbpXwmVk0-NKBt"
          title="Alfy Matching demo video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        <div
          style={{
            marginTop: "30px",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Link href="/pricing" alt="pricing alfy">
            <Button type="link" size="large">
              {t("viewPricing")}
            </Button>
          </Link>
          <AddToSlackButton app={app} style={{ width: "auto" }} />
        </div>
      </Modal>
      <Button
        type="link"
        icon={<VideoCameraOutlined />}
        onClick={() => {
          setIsModalOpen(true);
        }}
        size="large"
      >
        {t("watchADemo")}
      </Button>
    </>
  );
};
export default WatchADemoButton;
